window.addEventListener('load', () => {
  textBannerBlock.init();
});

const textBannerBlock = {
  init: function () {
    const sections = document.querySelectorAll('[data-text-banner-block-js]');

    if (!sections.length) return;

    sections.forEach((section) => {
      this.setSectionHeight(section);

      window.addEventListener('resize', () => {
        this.setSectionHeight(section);
      });
    })

  },

  setSectionHeight: function (section) {
    let bannerEl = section.querySelector('.text-banner-block__banner');
    let sectionInner = section.querySelector('.text-banner-block__inner');
    if (!bannerEl || !sectionInner) return;
    if (window.innerWidth >= 768) {
      let bannerHeight = bannerEl.clientHeight;
      sectionInner.style.height = bannerHeight / 2 + 'px';
    } else {
      sectionInner.style.height = '';
    }
  },

}

export default textBannerBlock;
